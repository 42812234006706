/*
 * @Author: wxy
 * @Describe:
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-20 03:07:59
 */

import { ref, readonly } from "vue";
import { globalConfig } from "@/common/config/global.config";
import { setValue, getValue } from "@/common/util/storage";
import route from "@/route/index";

const _pageTabs = ref([]);
_pageTabs.value = getValue(globalConfig.field.historyRoute) || [];

export const pageTabs = readonly(_pageTabs);

// 新增标签
export const setPageTabs = (tabsObj) => {
  if (!tabsObj) return;
  if (_pageTabs.value.every((item) => item.name !== tabsObj.name)) {
    _pageTabs.value.push(tabsObj);
    setValue(globalConfig.field.historyRoute, _pageTabs.value); // 保存数据
  }
};

// 删除标签
export const delPageTabs = (index) => {
  _pageTabs.value.splice(index, 1);
  setValue(globalConfig.field.historyRoute, _pageTabs.value); // 保存数据
};

