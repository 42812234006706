<!--
 * @Author: wxy
 * @Describe: 
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-20 03:10:08
-->

<template>
  <div
    class="flex padding-left-xxl padding-top-sm padding-bottom-sm theme-background-color"
  >
    <a-tag
      :closable="pageTabs.length > 1"
      class="menu-tag-style history-item"
      v-for="(item, index) in pageTabs"
      :key="item.name"
      @close="closePageTabs(index)"
      @click="router.push({ name: item.name })"
    >
      <template #closeIcon>
        <Wicon
          icon="CloseOutlined"
          :class="
            route.name === item.name ? 'history-item-select-text' : 'history-item-text'
          "
        />
      </template>
      <div
        class="tag-text"
        :class="
          route.name === item.name ? 'history-item-select-text' : 'history-item-text'
        "
      >
        {{ item.title }}
      </div>
    </a-tag>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import { pageTabs, delPageTabs } from "@/composition/usePageTabs";

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    // 关闭标签
    const closePageTabs = (index) => {
      if (route.name === pageTabs.value[index].name) {
        if (pageTabs.value[index + 1]) {
          router.push({ name: pageTabs.value[index + 1].name });
        } else if (pageTabs.value[index - 1]) {
          router.push({ name: pageTabs.value[index - 1].name });
        }
      }
      delPageTabs(index);
    };

    return { route, pageTabs, router, closePageTabs };
  },
});
</script>

<style lang="scss" scoped>
.menu-tag-style {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  min-width: 80px;
  max-width: 150px;
  cursor: pointer;
  .tag-text {
    width: 100%;
    letter-spacing: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
  }
}
</style>
